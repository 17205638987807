import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Contain from '../styled/Contain'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Panorama from '../components/Panorama'
import Teachers from '../components/Teachers'
import Copy from '../components/Copy'

export default function About({ data }) {
  return (
    <Layout>
      <Panorama title="Argentijnse tango, de dans voor iedereen" tertiary />
      <Contain>
        <Copy title={data.about.frontmatter.title} html={data.about.html} />
        <Teachers title="Onze docenten" />
        <Copy title={data.tango.frontmatter.title} html={data.tango.html} />
      </Contain>
    </Layout>
  )
}

export const Head = () => <SEO title="Over ons" />

export const pageQuery = graphql`
  query {
    about: markdownRemark(frontmatter: { path: { eq: "over-ons" } }) {
      html
      frontmatter {
        path
        title
      }
    }
    tango: markdownRemark(frontmatter: { path: { eq: "over-tango" } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

About.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}
