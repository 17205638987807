/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { H1, H2 } from '../styled/Heading'
import { Intro } from '../styled/Text'
import Contain from '../styled/Contain'
import pattern from '../images/pattern-tertiary.svg'

const Teachers = (props) => {
  const data = useStaticQuery(graphql`
    query TeachersQuery {
      allAirtable(
        filter: {
          table: { eq: "Docenten" }
          data: { Toon_op_website: { eq: true } }
        }
      ) {
        edges {
          node {
            data {
              Voornaam
              Achternaam
              Bio {
                childMarkdownRemark {
                  html
                }
              }
              Rol
              Foto {
                localFiles {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      width: 912
                      quality: 100
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { title, intro } = props

  // Array of all testimonials
  const teachers = data.allAirtable.edges

  const [active, setActive] = useState(teachers[0])
  const [activeIndex, setActiveIndex] = useState(0)

  const handleActive = (teacher, index) => {
    // Set clicked teacher to active
    setActive(teacher)
    // Set index to clicked index
    setActiveIndex(index)
  }

  return (
    <TeachersWrapper>
      <Contain size="medium">
        <H1 as="h2" marginTop>
          {title}
        </H1>
      </Contain>
      {intro && (
        <Contain size="small">
          <Intro>{intro}</Intro>
          <p>Test</p>
        </Contain>
      )}

      <TeachersGrid>
        <TeachersActive>
          <GatsbyImage
            image={
              active.node.data.Foto.localFiles[0].childImageSharp
                .gatsbyImageData
            }
            alt={active.node.data.Voornaam}
          />
          <H2>
            {active.node.data.Voornaam} {active.node.data.Achternaam}
          </H2>
          <span>{active.node.data.Rol}</span>
          <div
            dangerouslySetInnerHTML={{
              __html: active.node.data.Bio.childMarkdownRemark.html,
            }}
          />
        </TeachersActive>

        <TeachersList>
          {teachers.map((teacher, index) => (
            <li
              key={index}
              onClick={() => handleActive(teacher, index)}
              className={index === activeIndex ? 'is-active' : undefined}
            >
              <GatsbyImage
                image={
                  teacher.node.data.Foto.localFiles[0].childImageSharp
                    .gatsbyImageData
                }
                alt={teacher.node.data.Voornaam}
              />
            </li>
          ))}
        </TeachersList>
      </TeachersGrid>
    </TeachersWrapper>
  )
}

Teachers.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
}

export default Teachers

const TeachersWrapper = styled(Contain)`
  margin-top: 40px;

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    margin-top: 80px;
  }
`

const TeachersGrid = styled.div`
  display: grid;
  grid-column-gap: ${(props) => props.theme.gridColumnGap};
  grid-template-areas:
    'list'
    'active';
  margin-top: 64px;

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    grid-template-areas: 'active list';
    grid-template-columns: repeat(2, 1fr);
  }
`

const TeachersActive = styled.div`
  grid-area: active;
  margin-top: 8px;

  ${H2} {
    margin-top: 32px;
  }

  span {
    display: block;
    font-size: ${(props) => props.theme.fontSizes.intro};
    font-weight: ${(props) => props.theme.fontWeights.heading};
    letter-spacing: ${(props) => props.theme.letterSpacings.intro};

    &::before {
      height: 2px;
      width: 56px;
      content: '';
      display: block;
      margin-bottom: 16px;
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }

  p,
  span {
    margin-top: 32px;
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.medium}) {
    margin-top: 16px;
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    margin-top: 0;
  }
`

const TeachersList = styled.ul`
  display: grid;
  grid-area: list;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);

  li {
    position: relative;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    cursor: pointer;

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      z-index: 1;
      transition: opacity 0.2s ease-in-out;
      background-color: ${(props) => props.theme.colors.tertiary};
      mix-blend-mode: multiply;
      background-image: url(${pattern});
    }

    &.is-active {
      opacity: 0.5;
      cursor: default;
    }

    .gatsby-image-wrapper {
      height: 100%;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          opacity: 0;
        }

        &.is-active {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.medium}) {
    grid-gap: 16px;
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    grid-gap: ${(props) => props.theme.gridColumnGap};
    grid-template-columns: repeat(2, 1fr);
  }
`
